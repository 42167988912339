import * as React from "react";

import Entry from "../Components/Entries/Entry";
import Layout from "../Components/Layout/Layout";
// data
const data = [
  {
    title: "Extracinema",
    subtitle:
      "Website made for ExtraCinema, an educational project led by director Marco Chiarini and Marcello Signorile, promoting the teaching of cinematic language in schools in collaboration with institutions and movie theaters.",
    cover: "https://michelangeloalesi.it/images/cover-extracinema.png",
    link: "https://extracinema.it",
  },
  {
    title: "Full Works List",
    subtitle: "My online portfolio",
    link: "http://portfolio.michelangeloalesi.it/",
  },
];

// markup
const IndexPage = () => {
  return (
    <Layout>
      <div style={{ width: "50%", margin: "0 auto", textAlign: "center" }}>
        <img style={{ maxWidth: "200px" }} src="images/melting.svg" />
      </div>
      <h1 style={{ fontSize: "10em", margin: 0, textAlign: "center" }}>☞</h1>
      {/* {data.map((el, idx) => {
        return (
          <Entry
            title={el.title}
            subtitle={el.subtitle}
            cover={el.cover}
            link={el.link}
          />
        );
      })} */}
    </Layout>
  );
};

export default IndexPage;
